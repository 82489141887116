import { NavigateFunction } from "react-router-dom";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconLocationHistory } from "../../../ui/Icon/Line/LocationHistory";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import "../../../ui/Table/utils/TableBuilder.css";
import { getReportRouteNameTranslation } from "../../../utils/Utils";
import { Driver } from "../../driver/driversSlice";
import { FleetView } from "../../fleet/fleetViewsSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import { ReportArchive } from "./reportArchiveSlice";
import "./ReportsArchiveView.css";

export class ReportArchiveTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      driver?: Driver;
      fleetView?: FleetView;
      reportArchive?: ReportArchive;
    },
    navigate: NavigateFunction,
    preferencesContext: Preferences
  ) => {
    let row: Row = { render: {} as Row };

    const downloadFile = (tmpUrl: string, name: string, isPdf: boolean) => {
      const url = tmpUrl;
      const link = document.createElement("a");
      link.href = url;
      !isPdf && link.setAttribute("download", name);
      isPdf && link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const cronParser = require("cron-parser");

    function getNextSchedule(
      cronExpression: string,
      lastScheduleDate: string | number | Date
    ) {
      const options = {
        currentDate: new Date(lastScheduleDate),
        tz: "Europe/Rome",
      };

      try {
        const interval = cronParser.parseExpression(cronExpression, options);
        const nextDate = interval.next().toString();
        return nextDate;
      } catch (err) {}
    }

    let reportArchive: ReportArchive =
      entities.reportArchive ?? ({} as ReportArchive);

    const cronstrue = require("cronstrue/i18n");

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "reportGenerated":
            row.render.reportGenerated = (
              <div className="table-report-archive-row-element">
                {reportArchive?.lastScheduledReportExecution?.executionTime ? (
                  <>
                    <p className="reportMarked">
                      {new Date(
                        reportArchive?.lastScheduledReportExecution?.executionTime
                      ).getDate() +
                        "/" +
                        (new Date(
                          reportArchive?.lastScheduledReportExecution?.executionTime
                        ).getMonth() +
                          1) +
                        "/" +
                        new Date(
                          reportArchive?.lastScheduledReportExecution?.executionTime
                        ).getFullYear()}
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
            break;
          case "schedulationName":
            row.render.schedulationName = (
              <div className="table-report-archive-row-element">
                {reportArchive.name ? (
                  <>
                    <p className="reportMarked">{reportArchive?.name}</p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
            break;
          case "sentTo":
            row.render.sentTo = (
              <div className="table-report-archive-row-element">
                {reportArchive?.lastScheduledReportExecution?.user ? (
                  <>
                    <p className="reportMarked">
                      {reportArchive?.lastScheduledReportExecution?.user}
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );

            break;
          case "reportType":
            row.render.reportType = (
              <div className="table-report-archive-row-element">
                {reportArchive.type ? (
                  <>
                    <p className="reportMarked">
                      {getReportRouteNameTranslation(reportArchive?.type)}
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
            break;
          case "schedulationPeriod":
            row.render.schedulationPeriod = (
              <div className="table-report-archive-row-element">
                {reportArchive?.cronExpression ? (
                  <>
                    <p
                      className="reportMarked"
                      style={{ whiteSpace: "normal", maxWidth: " 200px" }}
                    >
                      {cronstrue.toString(reportArchive?.cronExpression, {
                        locale: preferencesContext.language,
                      })}
                    </p>
                  </>
                ) : (
                  <p className="reportMarked">
                    {new Date(
                      reportArchive?.lastScheduledReportExecution?.executionTime
                    ).getDate() +
                      "/" +
                      (new Date(
                        reportArchive?.lastScheduledReportExecution?.executionTime
                      ).getMonth() +
                        1) +
                      "/" +
                      new Date(
                        reportArchive?.lastScheduledReportExecution?.executionTime
                      ).getFullYear()}
                  </p>
                )}
              </div>
            );
            break;
          case "nextSchedulationDate":
            const cronExpression = reportArchive?.cronExpression;
            const lastScheduleDate =
              reportArchive?.lastScheduledReportExecution?.executionTime;
            const nextDate = getNextSchedule(
              cronExpression,
              lastScheduleDate
            ) as Date;

            row.render.nextSchedulationDate = (
              <div className="table-report-archive-row-element">
                {cronExpression && nextDate?.getDate() !== undefined ? (
                  <>
                    <p className="reportMarked">
                      {nextDate?.getDate() +
                        "/" +
                        (nextDate?.getMonth() + 1) +
                        "/" +
                        nextDate?.getFullYear()}
                    </p>
                  </>
                ) : (
                  <p className="reportMarked">--:--:----</p>
                )}
              </div>
            );
            break;
          case "download":
            row.render.download = (
              <div
                className="table-report-archive-row-element"
                style={{
                  justifyContent: "center",
                  display: "flex",
                  cursor: reportArchive?.lastScheduledReportExecution?.pdfUrl
                    ? "pointer"
                    : "var(--global-cursor-not-allowed) !important",
                  opacity:
                    reportArchive?.lastScheduledReportExecution?.pdfUrl ||
                    reportArchive?.lastScheduledReportExecution?.excelUrl
                      ? "inherit"
                      : "50%",
                }}
                onClick={() => {
                  reportArchive?.lastScheduledReportExecution?.pdfUrl
                    ? downloadFile(
                        reportArchive?.lastScheduledReportExecution?.pdfUrl,
                        reportArchive?.lastScheduledReportExecution
                          ?.pdfReportName,
                        true
                      )
                    : reportArchive?.lastScheduledReportExecution?.excelUrl &&
                      downloadFile(
                        reportArchive?.lastScheduledReportExecution?.excelUrl,
                        reportArchive?.lastScheduledReportExecution
                          ?.excelReportName,
                        false
                      );
                }}
              >
                <IconDownload size={14} color={"#35465B"} />
              </div>
            );
            break;
          case "history":
            row.render.history = (
              <div
                className="table-report-archive-row-element"
                style={{
                  justifyContent: "center",
                  cursor: "pointer",
                  display: "flex",
                  marginRight: "20px",
                }}
                onClick={() =>
                  navigate(`/reports/schedulation/archive/${reportArchive.id}`)
                }
              >
                <IconLocationHistory size={14} color="#35465B" />
              </div>
            );
            break;
          default:
            break;
        }
      });

    return row;
  };
}
