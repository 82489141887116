import { t } from "i18next";
import { store } from "../../../app/store";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconLocatorOff } from "../../../ui/Icon/Line/LocatorOff";
import { IconTrash } from "../../../ui/Icon/Line/Trash";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import { ToastNotification } from "../../../utils/ToastNotification";
import {
  deleteReportExecutionAsync,
  removeReportExecutionById,
  ReportArchiveExecutions,
  resendReportExecutionAsync,
} from "./reportArchiveExecutionsSlice";

export class ArchiveModalTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (entities: { reportExecution?: ReportArchiveExecutions }) => {
    let reportExecution: ReportArchiveExecutions =
      entities.reportExecution ?? ({} as ReportArchiveExecutions);
    let row: Row = { render: {} as Row };
    let columns = [
      { name: "reportGenerated" },
      { name: "resend" },
      { name: "downloadPDF" },
      { name: "downloadEXCEL" },
      { name: "delete" },
    ];

    const downloadFile = (tmpUrl: string, name: string, isPdf: boolean) => {
      const url = tmpUrl;
      const link = document.createElement("a");
      link.href = url;
      !isPdf && link.setAttribute("download", name);
      isPdf && link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    columns &&
      columns.forEach((column) => {
        switch (column.name) {
          case "reportGenerated":
            row.render.reportGenerated = (
              <div className="table-report-archive-row-element">
                {reportExecution.executionTime ? (
                  <>
                    <p className="reportMarked">
                      {new Date(reportExecution?.executionTime).getDate() +
                        "/" +
                        (new Date(reportExecution?.executionTime).getMonth() +
                          1) +
                        "/" +
                        new Date(reportExecution?.executionTime).getFullYear()}
                    </p>
                  </>
                ) : (
                  <></>
                )}
              </div>
            );
            break;

          case "resend":
            row.render.resend = (
              <div
                className="table-report-archive-row-element"
                style={{
                  justifyContent: "center",
                  cursor: "pointer",
                  display: "flex",
                }}
                onClick={() =>
                  store
                    .dispatch(
                      resendReportExecutionAsync({
                        executionsId: reportExecution?.id?.toString(),
                      })
                    )
                    .then((response) => {
                      response.meta.requestStatus === "fulfilled"
                        ? ToastNotification({
                            toastId: "reportArchiveResended",
                            status: "success",
                            description: t("report.archive.modal.reportSended"),
                          })
                        : ToastNotification({
                            toastId: "reportArchiveResended",
                            status: "error",
                            description: t("common.networkError"),
                          });
                    })
                }
              >
                <IconLocatorOff size={14} color="#35465B" />
              </div>
            );
            break;
          case "downloadPDF":
            row.render.downloadPDF = (
              <div className="table-report-archive-row-element">
                {
                  <div
                    className="table-report-archive-row-element"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      cursor: reportExecution?.pdfUrl
                        ? "pointer"
                        : "var(--global-cursor-not-allowed) !important",
                      opacity: reportExecution?.pdfUrl ? "inherit" : "50%",
                    }}
                    onClick={() => {
                      reportExecution?.pdfUrl &&
                        downloadFile(
                          reportExecution.pdfUrl,
                          reportExecution.pdfReportName,
                          true
                        );
                    }}
                  >
                    <IconDownload size={14} color={"#35465B"} />
                  </div>
                }
              </div>
            );
            break;
          case "downloadEXCEL":
            row.render.downloadExcel = (
              <div className="table-report-archive-row-element">
                {
                  <div
                    className="table-report-archive-row-element"
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      cursor: reportExecution?.excelUrl
                        ? "pointer"
                        : "var(--global-cursor-not-allowed) !important",
                      opacity: reportExecution?.excelUrl ? "inherit" : "50%",
                    }}
                    onClick={() => {
                      reportExecution?.excelUrl &&
                        downloadFile(
                          reportExecution.excelUrl,
                          reportExecution.excelReportName,
                          false
                        );
                    }}
                  >
                    <IconDownload size={14} color={"#35465B"} />
                  </div>
                }
              </div>
            );
            break;
          case "delete":
            row.render.delete = (
              <div
                className="table-report-archive-row-element"
                style={{
                  justifyContent: "center",
                  paddingRight: "10px",
                  cursor: "pointer",
                  display: "flex",
                }}
                onClick={() =>
                  store
                    .dispatch(
                      deleteReportExecutionAsync({
                        executionsId: reportExecution?.id?.toString(),
                      })
                    )
                    .then((response) => {
                      if (response.meta.requestStatus === "fulfilled") {
                        ToastNotification({
                          toastId: "reportArchiveDeleted",
                          status: "success",
                          description: t("report.archive.modal.reportDeleted"),
                        });
                        store.dispatch(
                          removeReportExecutionById(reportExecution?.id)
                        );
                      } else {
                        ToastNotification({
                          toastId: "reportArchiveResended",
                          status: "error",
                          description: t("common.networkError"),
                        });
                      }
                    })
                }
              >
                <IconTrash size={14} color="#35465B" />
              </div>
            );
            break;
          default:
            break;
        }
      });

    return row;
  };
}
