import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { Button } from "../../../ui/Button/Button";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import { DatePickerDropdown } from "../../../ui/Forms/DatePickerDropdown";
import Form from "../../../ui/Forms/Form";
import SearchField from "../../../ui/Forms/SearchField";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import {
  getQueryString,
  getReportRouteNameTranslation,
} from "../../../utils/Utils";
import { selectReportArchiveExecutionsStatusSliceStatus } from "./reportArchiveExecutionsSlice";
import {
  getAllScheduledReportAsync,
  selectReportArchiveStatusSliceStatus,
} from "./reportArchiveSlice";

interface ReportsArchiveFilterBarProps {
  types: any;
  filter: (params: any) => any;
  onlyDate: boolean;
}

interface QueryParams {
  [paramName: string]: any;
}

export const ReportsArchiveFilterBar: React.FC<ReportsArchiveFilterBarProps> =
  ({ onlyDate, types, filter }) => {
    const [searchParams] = useSearchParams();
    const [schedulationName, setSchedulationName] = useState("");
    const [generationDate, setGenerationDate] = useState<string[]>(["", ""]);
    const [initialDateValue, setInitialDateValue] = useState<Date[]>([]);
    const [reportType, setReportType] = useState<any>();
    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;
    const navigate = useNavigate();
    const [stringifiedQueryString, setStringifiedQueryString] = useState("");

    const archiveSliceStatus = useAppSelector(
      selectReportArchiveStatusSliceStatus
    );

    const archiveExecutionSliceStatus = useAppSelector(
      selectReportArchiveExecutionsStatusSliceStatus
    );

    useEffect(() => {
      queryParams["page"] = 0;
      queryParams["size"] = 10;
      !onlyDate && setStringifiedQueryString(getQueryString(queryParams));
      filter(getQueryString(queryParams));
      !onlyDate &&
        (queryParams["sort"] = "scheduledReportExecutions.executionTime,ASC");

      if (!onlyDate) {
        const currentSearchParams =
          searchParams.toString() !== "" ? searchParams : null;
        let dates = [] as Date[];
        const startPeriod = currentSearchParams?.get("startPeriod");
        const endPeriod = currentSearchParams?.get("endPeriod");
        const initialNameParam = currentSearchParams?.get("nameSchedulation");
        const initialTypeParam = currentSearchParams?.get("type");
        if (startPeriod && endPeriod) {
          dates.push(new Date(startPeriod));
          dates.push(new Date(endPeriod));
          setInitialDateValue(dates);
        }
        initialNameParam && setSchedulationName(initialNameParam);
        initialTypeParam &&
          setReportType({
            id: initialTypeParam,
            label:
              reportElements[initialTypeParam as keyof typeof reportElements],
          });
        if (searchParams.toString() !== "") {
          store.dispatch(
            getAllScheduledReportAsync({
              queryParams: "?" + searchParams.toString(),
            })
          );
        } else {
          store.dispatch(
            getAllScheduledReportAsync({
              queryParams:
                getQueryString({
                  size: 10,
                  page: 0,
                }) + "sort=scheduledReportExecutions.executionTime,DESC",
            })
          );
        }
      }
    }, []);

    //#region Type
    const reportElements = {
      VEHICLE_TRAVEL_REPORT: getReportRouteNameTranslation(
        "VEHICLE_TRAVEL_REPORT"
      ),
      VEHICLE_STOP_REPORT: getReportRouteNameTranslation("VEHICLE_STOP_REPORT"),
      DRIVER_TRAVEL_REPORT: getReportRouteNameTranslation(
        "DRIVER_TRAVEL_REPORT"
      ),
      GPSDATA_ROUTE_REPORT: getReportRouteNameTranslation(
        "GPSDATA_ROUTE_REPORT"
      ),
      GPSDATA_STOP_REPORT: getReportRouteNameTranslation("GPSDATA_STOP_REPORT"),
      GEOFENCE_ROUTE_REPORT: getReportRouteNameTranslation(
        "GEOFENCE_ROUTE_REPORT"
      ),
      GEOFENCE_STOP_REPORT: getReportRouteNameTranslation(
        "GEOFENCE_STOP_REPORT"
      ),
      GEOFENCE_ROUTE_AND_STOP_REPORT: getReportRouteNameTranslation(
        "GEOFENCE_ROUTE_AND_STOP_REPORT"
      ),
      EVENTS_IO_REPORT: getReportRouteNameTranslation("EVENTS_IO_REPORT"),
      UTILIZATION_REPORT: getReportRouteNameTranslation("UTILIZATION_REPORT"),
      REFUELLING_REPORT: getReportRouteNameTranslation("REFUELLING_REPORT"),
      LOGS_REPORT: getReportRouteNameTranslation("LOGS_REPORT"),
      VEHICLE_ACTIVITY_REPORT: getReportRouteNameTranslation(
        "VEHICLE_ACTIVITY_REPORT"
      ),
      DAILY_ACTIVITY_REPORT: getReportRouteNameTranslation(
        "DAILY_ACTIVITY_REPORT"
      ),
      INFRINGEMENT_REPORT: getReportRouteNameTranslation("INFRINGEMENT_REPORT"),
    } as const;

    const reportTypes = Object.keys(reportElements).map((key) => ({
      id: key,
      label: reportElements[key as keyof typeof reportElements],
    }));

    const handleChanges = (
      params: Map<string, string[] | string>,
      generateImmediatly?: boolean
    ): void => {
      if (!!params) {
        params.forEach((value, key) => {
          if (!!value) {
            queryParams[key] = value;
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
            }
          }
        });

        queryParams["endPeriod"] == null && delete queryParams["startPeriod"];

        setStringifiedQueryString(getQueryString(queryParams));
        !onlyDate &&
          navigate(
            "/reports/schedulation/archive" + getQueryString(queryParams)
          );
      }
    };

    return (
      <>
        <div
          className="report-archive-filterbar"
          style={{ borderBottom: onlyDate ? "none" : "inerhit" }}
        >
          {!onlyDate && (
            <Form>
              <SearchField
                name="search"
                id="search-field"
                size="small"
                placeholder={t("report.archive.schedulationName")}
                value={schedulationName}
                onChange={(val) => {
                  setSchedulationName(val);
                  const map = new Map();
                  map.set("nameSchedulation", val);
                  handleChanges(map);
                }}
              />
            </Form>
          )}
          <Form>
            <DatePickerDropdown
              icon={<IconCalendar size={14} color="#687484" />}
              placeholder={t("report.archive.generationDate")}
              initialValue={initialDateValue}
              setDate={(val) => {
                const period = [];
                if (val[0]) {
                  period.push(val[0].format("YYYY/MM/DD"));
                }
                if (val[1]) {
                  period.push(val[1].format("YYYY/MM/DD"));
                }
                setGenerationDate(period);

                const map = new Map();
                const startPeriod =
                  typeof val[0] === "string"
                    ? val[0]
                    : val[0].format("YYYY-MM-DDTHH:mm");
                map.set("startPeriod", startPeriod);
                map.set("endPeriod", val[1]?.format("YYYY-MM-DDTHH:mm") ?? "");

                if (map.get("endPeriod") === "") {
                  map.delete("startPeriod");
                  map.delete("endPeriod");
                  delete queryParams["startPeriod"];
                  delete queryParams["endPeriod"];
                }

                handleChanges(map);
              }}
            />
          </Form>
          {!onlyDate && (
            <div style={{ width: "fit-content" }}>
              <Dropdown
                value={reportType}
                isClearable={false}
                itemAttribute="label"
                placeholder={t("admin.driver.editViewCreate.licenseType")}
                size="small"
                onChange={(val) => {
                  setReportType(val);
                  const map = new Map();
                  map.set("type", val[0].id);
                  handleChanges(map);
                }}
                options={reportTypes}
              />
            </div>
          )}
          <Button
            isLoading={
              archiveSliceStatus === "loading" ||
              archiveExecutionSliceStatus === "loading"
            }
            aspect="primary"
            size={"small"}
            label={t("common.search")}
            onClick={() => filter(stringifiedQueryString)}
          />
        </div>
      </>
    );
  };
