import { t } from "i18next";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { Button } from "../../../ui/Button/Button";
import { ElementType } from "../../../ui/Group/ElementTypeEnum";
import { TabSection } from "../../../ui/Tabs/TabSection";
import { Tabs } from "../../../ui/Tabs/Tabs";
import { ThumbProfile } from "../../../ui/ThumbProfile/ThumbProfile";
import { getFullDate } from "../../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../../utils/ToastNotification";
import {
  getIllustrationFromVehicleType,
  getQueryString,
} from "../../../utils/Utils";
import {
  AdminPrivilegesResourceTab,
  ResourceProps,
} from "../../components/AdminPrivilegesResourceTab";
import { Driver, driversSelectors } from "../../driver/driversSlice";
import {
  DriverStatus,
  driversStatusSelectors,
  getFilteredDriversStatusAndDetailsAsync,
  selectDriverStatusSliceReasonCode,
  selectDriverStatusSliceStatus,
} from "../../driver/driversStatusSlice";
import {
  Geofence,
  geofencesSelectors,
  getGeofencesAsync,
  selectGeofencesSliceReasonCode,
  selectGeofencesSliceStatus,
} from "../../geofence/geofenceSlice";
import {
  GeofenceCategory,
  geofenceCategoriesSelectors,
  getGeofenceCategoriesAsync,
  selectGeofenceCategoriesSliceReasonCode,
  selectGeofenceCategoriesSliceStatus,
} from "../../geofenceCategory/geofenceCategoriesSlice";
import { UserPermissions } from "../../users/privilege/privilegesSlice";
import { Vehicle, vehiclesSelectors } from "../../vehicle/vehiclesSlice";
import {
  VehicleStatus,
  getVehiclesAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusSelectors,
} from "../../vehicle/vehiclesStatusSlice";
import "./AdminNotificationFilterBar.css";
import "./AdminNotificationManagement.css";
import { NotificationRuleAggregation } from "./notificationRuleAggregationSlice";

interface AdminNotificationRuleEntitiesProps {
  permissions: UserPermissions;
  notificationRuleAggregation: NotificationRuleAggregation;
  manageEntities: boolean;
  setModifiedPrivilegeOutside: (a: any) => any;
  setVehiclesCanSeeOutside: (a: any) => any;
  setDriversCanSeeOutside: (a: any) => any;
  setGeofencesCanSeeOutside: (a: any) => any;
  setPrivilegesHasChangedOutSide: (a: any) => any;
}

export type SelectedEntities = {
  vehicle: number[];
  driver: number[];
  geofence: number[];
};

const AdminNotificationRuleEntities: React.FC<AdminNotificationRuleEntitiesProps> =
  ({
    permissions,
    notificationRuleAggregation,
    manageEntities,
    setModifiedPrivilegeOutside,
    setVehiclesCanSeeOutside,
    setDriversCanSeeOutside,
    setGeofencesCanSeeOutside,
    setPrivilegesHasChangedOutSide,
  }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;

    const isAddRule = pathname.includes("/add-rule");
    const isViewRule = pathname.includes("/view-rule");

    const [modifiedPrivilege, setModifiedPrivilege] =
      useState<SelectedEntities>({} as SelectedEntities);
    const [vehiclesCanSee, setVehiclesCanSee] = useState<ResourceProps[]>(
      [] as ResourceProps[]
    );
    const [driversCanSee, setDriversCanSee] = useState<ResourceProps[]>(
      [] as ResourceProps[]
    );
    const [geofencesCanSee, setGeofencesCanSee] = useState<ResourceProps[]>(
      [] as ResourceProps[]
    );

    /**
     * Initialization of entities by dipatching the download APIs for:
     * - vehicles
     * - geofences
     * - drivers
     */
    useEffect(() => {
      vehicles.length === 0 && store.dispatch(getVehiclesAsync());
      drivers.length === 0 &&
        store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
      geofences.length === 0 &&
        store.dispatch(
          getGeofencesAsync({
            queryParams: getQueryString({ status: "ACTIVE" }),
          })
        );
      geofenceCategories.length === 0 &&
        store.dispatch(getGeofenceCategoriesAsync());
    }, []);

    //#region NOTIFICATION RULE ENTITIES

    //#region vehicles variables
    const [vehiclesCannotSee, setVehiclesCannotSee] = useState(
      [] as ResourceProps[]
    );

    let vehicles: Vehicle[] = useAppSelector((state) =>
      vehiclesSelectors.selectAll(state)
    );

    let vehiclesStatus: VehicleStatus[] = useAppSelector((state) =>
      vehiclesStatusSelectors.selectAll(state)
    );
    const vehiclesStatusSliceStatus = useAppSelector(
      selectVehiclesStatusSliceStatus
    );
    const vehiclesStatusSliceReasonCode = useAppSelector(
      selectVehiclesStatusSliceReasonCode
    );

    /**
     * This useEffect separates into two list (can see and cannot se) the downloaded vehicles
     * on the basis of the current editing user privileges.
     */
    useEffect(() => {
      if (
        !isAddRule &&
        vehicles.length > 0 &&
        vehiclesStatus.length > 0 &&
        !_.isEmpty(notificationRuleAggregation)
      ) {
        const vehiclesForPrivilegesTab = vehicles.map((vehicle) => {
          const vehicleStatus = vehiclesStatus.find((x) => x.id === vehicle.id);
          return {
            key: vehicle.id,
            id: vehicle.id,
            firstTitle: vehicle.alias,
            secondTitle: vehicle.plate,
            firstSubtitle: vehicle.status,
            secondSubtitle: getFullDate(
              vehicleStatus?.dynamicFields?.lastUpdate,
              false
            ),
            type: ElementType.vehicle,
            icon: getIllustrationFromVehicleType(vehicle),
          } as ResourceProps;
        });
        if (vehiclesForPrivilegesTab.length > 0 && permissions.vehicles.write) {
          const vehiclesPrivileges =
            notificationRuleAggregation.notificationRulesEntities
              .filter((x) => x.notificationRuleEntityType === "VEHICLE")
              .map((x) => x.notificationRuleEntityId);
          const vehiclesCannotSeeTemp = vehiclesForPrivilegesTab.filter(
            (el) => {
              return !vehiclesPrivileges.includes(el.id);
            }
          );

          setVehiclesCannotSee(
            vehiclesCannotSeeTemp.length > 0
              ? vehiclesCannotSeeTemp
              : vehiclesForPrivilegesTab
          );

          let vehiclesCanSeeTemp = vehiclesForPrivilegesTab.filter((el) => {
            return notificationRuleAggregation.notificationRulesEntities.some(
              (privilege) =>
                privilege.notificationRuleEntityType === "VEHICLE" &&
                el.id === privilege.notificationRuleEntityId
            );
          });

          const modifiedPrivilegeTemp = {
            ...modifiedPrivilege,
            vehicle: vehiclesCanSeeTemp.map(
              (associatedValue) => associatedValue.id
            ),
          } as SelectedEntities;
          setModifiedPrivilege(modifiedPrivilegeTemp);
          setModifiedPrivilegeOutside(modifiedPrivilegeTemp);

          setVehiclesCanSee(vehiclesCanSeeTemp);
          setVehiclesCanSeeOutside(vehiclesCanSeeTemp);
        }
      }
    }, [vehicles, vehiclesStatus, notificationRuleAggregation]);

    useEffect(() => {
      if (isAddRule && vehicles.length > 0 && vehiclesStatus.length > 0) {
        const vehiclesCannotSeeTemp = vehicles.map((vehicle) => {
          const vehicleStatus = vehiclesStatus.find((x) => x.id === vehicle.id);
          return {
            key: vehicle.id,
            id: vehicle.id,
            firstTitle: vehicle.alias,
            secondTitle: vehicle.plate,
            firstSubtitle: vehicle.status,
            secondSubtitle: getFullDate(
              vehicleStatus?.dynamicFields?.lastUpdate,
              false
            ),
            type: ElementType.vehicle,
            icon: getIllustrationFromVehicleType(vehicle),
          } as ResourceProps;
        });

        setVehiclesCannotSee(vehiclesCannotSeeTemp);
      }
    }, [vehicles, vehiclesStatus]);
    //#endregion

    //#region drivers variables
    const [driversCannotSee, setDriversCannotSee] = useState(
      [] as ResourceProps[]
    );
    let drivers: Driver[] = useAppSelector((state) =>
      driversSelectors.selectAll(state)
    );

    let driversStatus: DriverStatus[] = useAppSelector((state) =>
      driversStatusSelectors.selectAll(state)
    );
    const driversStatusSliceStatus = useAppSelector(
      selectDriverStatusSliceStatus
    );
    const driversStatusSliceReasonCode = useAppSelector(
      selectDriverStatusSliceReasonCode
    );

    /**
     * This useEffect separates into two list (can see and cannot se) the downloaded drivers
     * on the basis of the current editing user privileges.
     */
    useEffect(() => {
      if (
        !isAddRule &&
        drivers.length > 0 &&
        driversStatus.length > 0 &&
        !_.isEmpty(notificationRuleAggregation)
      ) {
        const driversForPrivilegesTab = drivers.map((driver) => {
          const driverStatus = driversStatus.find((x) => x.id === driver.id);
          return {
            key: driver.id,
            id: driver.id,
            firstTitle: `${driver.firstName} ${driver.lastName}`,
            firstSubtitle: driver.status,
            secondSubtitle: getFullDate(
              driverStatus?.dynamicFields?.lastUpdate,
              false
            ),
            type: ElementType.driver,
            icon: (
              <ThumbProfile
                size="small"
                alt="Driver Thumbnail"
                imgUrl={driver.avatar}
              />
            ),
          } as ResourceProps;
        });
        if (driversForPrivilegesTab.length > 0 && permissions.drivers.write) {
          const driversPrivileges =
            notificationRuleAggregation.notificationRulesEntities
              .filter((x) => x.notificationRuleEntityType === "DRIVER")
              .map((x) => x.notificationRuleEntityId);
          const driversCannotSeeTemp = driversForPrivilegesTab.filter((el) => {
            return !driversPrivileges.includes(el.id);
          });

          setDriversCannotSee(
            driversCannotSeeTemp.length > 0
              ? driversCannotSeeTemp
              : driversForPrivilegesTab
          );

          let driverCanSeeTemp = driversForPrivilegesTab.filter((el) => {
            return notificationRuleAggregation.notificationRulesEntities.some(
              (privilege) =>
                privilege.notificationRuleEntityType === "DRIVER" &&
                el.id === privilege.notificationRuleEntityId
            );
          });

          const modifiedPrivilegeTemp = {
            ...modifiedPrivilege,
            driver: driverCanSeeTemp.map(
              (associatedValue) => associatedValue.id
            ),
          } as SelectedEntities;
          setModifiedPrivilege(modifiedPrivilegeTemp);
          setModifiedPrivilegeOutside(modifiedPrivilegeTemp);

          setDriversCanSee(driverCanSeeTemp);
          setDriversCanSeeOutside(driverCanSeeTemp);
        }
      }
    }, [drivers, driversStatus, notificationRuleAggregation]);

    useEffect(() => {
      if (isAddRule && drivers.length > 0 && driversStatus.length > 0) {
        const driversCannotSeeTemp = drivers.map((driver) => {
          const driverStatus = driversStatus.find((x) => x.id === driver.id);
          return {
            key: driver.id,
            id: driver.id,
            firstTitle: `${driver.firstName} ${driver.lastName}`,
            firstSubtitle: driver.status,
            secondSubtitle: getFullDate(
              driverStatus?.dynamicFields?.lastUpdate,
              false
            ),
            type: ElementType.driver,
            icon: (
              <ThumbProfile
                size="small"
                alt="Driver Thumbnail"
                imgUrl={driver.avatar}
              />
            ),
          } as ResourceProps;
        });
        setDriversCannotSee(driversCannotSeeTemp);
      }
    }, [drivers, driversStatus]);
    //#endregion

    //#region geofences variables
    const [geofencesCannotSee, setGeofencesCannotSee] = useState(
      [] as ResourceProps[]
    );
    let geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
    let geofenceCategories: GeofenceCategory[] = useAppSelector(
      geofenceCategoriesSelectors.selectAll
    );
    const geofenceCategoriesSliceStatus = useAppSelector(
      selectGeofenceCategoriesSliceStatus
    );
    const geofenceCategoriesSliceReasonCode = useAppSelector(
      selectGeofenceCategoriesSliceReasonCode
    );

    const geofencesSliceStatus = useAppSelector(selectGeofencesSliceStatus);
    const geofencesSliceReasonCode = useAppSelector(
      selectGeofencesSliceReasonCode
    );

    /**
     * This useEffect separates into two list (can see and cannot se) the downloaded geofences
     * on the basis of the current editing user privileges.
     */
    useEffect(() => {
      if (
        !isAddRule &&
        geofences.length > 0 &&
        geofenceCategories.length > 0 &&
        !_.isEmpty(notificationRuleAggregation)
      ) {
        const geofencesForPrivilegesTab = geofences.map((geofence) => {
          const geofenceCategory = geofenceCategories.find(
            (x) => x.id === geofence.geofenceCategory
          );
          const resourceProp = {
            key: geofence.id,
            id: geofence.id,
            firstTitle: geofence.name,
            secondTitle: geofenceCategory?.name,
            type: ElementType.geofence,
            icon: getIllustrationFromVehicleType(geofence),
            categoryColor: geofenceCategory?.color,
          } as ResourceProps;
          return resourceProp;
        });
        if (
          geofencesForPrivilegesTab.length > 0 &&
          permissions.geofences.write
        ) {
          const geofencesPrivileges =
            notificationRuleAggregation.notificationRulesEntities
              .filter((x) => x.notificationRuleEntityType === "GEOFENCE")
              .map((x) => x.notificationRuleEntityId);
          const geofencesCannotSeeTemp = geofencesForPrivilegesTab.filter(
            (el) => {
              return !geofencesPrivileges.includes(el.id);
            }
          );

          setGeofencesCannotSee(
            geofencesCannotSeeTemp.length > 0
              ? geofencesCannotSeeTemp
              : geofencesForPrivilegesTab
          );
          let geofencesCanSeeTemp = geofencesForPrivilegesTab.filter((el) => {
            return notificationRuleAggregation.notificationRulesEntities.some(
              (privilege) =>
                privilege.notificationRuleEntityType === "GEOFENCE" &&
                el.id === privilege.notificationRuleEntityId
            );
          });

          const modifiedPrivilegeTemp = {
            ...modifiedPrivilege,
            geofence: geofencesCanSeeTemp.map(
              (associatedValue) => associatedValue.id
            ),
          } as SelectedEntities;
          setModifiedPrivilege(modifiedPrivilegeTemp);
          setModifiedPrivilegeOutside(modifiedPrivilegeTemp);

          setGeofencesCanSee(geofencesCanSeeTemp);
          setGeofencesCanSeeOutside(geofencesCanSeeTemp);
        }
      }
    }, [geofences, geofenceCategories, notificationRuleAggregation]);

    useEffect(() => {
      if (isAddRule && geofences.length > 0 && geofenceCategories.length > 0) {
        const geofencesCannotSeeTemp = geofences.map((geofence) => {
          const geofenceCategory = geofenceCategories.find(
            (x) => x.id === geofence.geofenceCategory
          );
          const resourceProp = {
            key: geofence.id,
            id: geofence.id,
            firstTitle: geofence.name,
            secondTitle: geofenceCategory?.name,
            type: ElementType.geofence,
            icon: getIllustrationFromVehicleType(geofence),
            categoryColor: geofenceCategory?.color,
          } as ResourceProps;
          return resourceProp;
        });
        setGeofencesCannotSee(geofencesCannotSeeTemp);
      }
    }, [geofences, geofenceCategories]);

    //#endregion

    //#endregion NOTIFICATION RULE ENTITIES

    //#region Toast notifications

    useEffect(() => {
      if (geofenceCategoriesSliceStatus === "failed") {
        if (geofenceCategoriesSliceReasonCode === "") {
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
        }
      }
    }, [geofenceCategoriesSliceStatus, geofenceCategoriesSliceReasonCode]);

    useEffect(() => {
      if (geofencesSliceStatus === "failed") {
        //gestire not found
        if (geofencesSliceReasonCode === "") {
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
        }
      }
    }, [geofencesSliceStatus, geofencesSliceReasonCode]);
    useEffect(() => {
      if (
        driversStatusSliceStatus === "failed" &&
        driversStatusSliceReasonCode === ""
      ) {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [driversStatusSliceStatus, driversStatusSliceReasonCode]);

    useEffect(() => {
      if (
        vehiclesStatusSliceStatus === "failed" &&
        vehiclesStatusSliceReasonCode === ""
      ) {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }, [vehiclesStatusSliceStatus, vehiclesStatusSliceReasonCode]);
    // #endregion Toast notifications
    //#endregion Toast notifications

    return (
      <>
        {manageEntities && (
          <div
            className="privileges-tabs-container"
            style={{ position: "relative" }}
          >
            <Tabs>
              <TabSection label="Vehicles">
                <AdminPrivilegesResourceTab
                  disabled={!permissions.drivers.write || isViewRule}
                  tabType="vehicles"
                  customTitleFirstGroup={t(
                    "admin.notifications.actions.entity.itemsIncluded"
                  )}
                  customTitleSecondGroup={t(
                    "admin.notifications.actions.entity.itemsNotIncluded"
                  )}
                  leftButtonText={t(
                    "admin.groups.editGroups.widgetButtonLabelRemove"
                  )}
                  rightButtonText={t(
                    "admin.groups.editGroups.widgetButtonLabelAdd"
                  )}
                  rightGroupProp={vehiclesCannotSee}
                  leftGroupProp={vehiclesCanSee}
                  output={(
                    leftResources: ResourceProps[],
                    rightResources: ResourceProps[]
                  ) => {
                    setPrivilegesHasChangedOutSide(true);
                    // Updating setModifiedPrivilege with selected elements
                    const modifiedPrivilegeTemp = {
                      ...modifiedPrivilege,
                      vehicle: rightResources.map((resource) => resource.id),
                    } as SelectedEntities;
                    setModifiedPrivilege(modifiedPrivilegeTemp);
                    setModifiedPrivilegeOutside(modifiedPrivilegeTemp);

                    setVehiclesCanSee(rightResources);
                    setVehiclesCanSeeOutside(rightResources);
                    setVehiclesCannotSee(leftResources);
                  }}
                />
              </TabSection>
              <TabSection label="Drivers">
                <div
                  style={{
                    position: "absolute",
                    right: "50px",
                    top: "0px",
                  }}
                >
                  <Button
                    size="small"
                    aspect="secondary"
                    label={t("admin.adminFilterBar.addDriver")}
                    onClick={() => window.open("/admin/drivers/new", "_blank")}
                  />
                </div>
                <AdminPrivilegesResourceTab
                  disabled={!permissions.drivers.write || isViewRule}
                  tabType="drivers"
                  customTitleFirstGroup={t(
                    "admin.notifications.actions.entity.itemsIncluded"
                  )}
                  customTitleSecondGroup={t(
                    "admin.notifications.actions.entity.itemsNotIncluded"
                  )}
                  leftButtonText={t(
                    "admin.groups.editGroups.widgetButtonLabelRemove"
                  )}
                  rightButtonText={t(
                    "admin.groups.editGroups.widgetButtonLabelAdd"
                  )}
                  rightGroupProp={driversCannotSee}
                  leftGroupProp={driversCanSee}
                  output={(
                    leftResources: ResourceProps[],
                    rightResources: ResourceProps[]
                  ) => {
                    setPrivilegesHasChangedOutSide(true);
                    // Updating setModifiedPrivilege with selected elements
                    const modifiedPrivilegeTemp = {
                      ...modifiedPrivilege,
                      driver: rightResources.map((resource) => resource.id),
                    } as SelectedEntities;
                    setModifiedPrivilege(modifiedPrivilegeTemp);
                    setModifiedPrivilegeOutside(modifiedPrivilegeTemp);

                    setDriversCanSee(rightResources);
                    setDriversCanSeeOutside(rightResources);
                    setDriversCannotSee(leftResources);
                  }}
                />
              </TabSection>
              <TabSection label="Geofences">
                <div
                  style={{
                    position: "absolute",
                    right: "50px",
                    top: "0px",
                  }}
                >
                  <Button
                    size="small"
                    aspect="secondary"
                    label={t("admin.geofences.geofenceFilterBar.addGeofence")}
                    onClick={() =>
                      window.open("/admin/geofences/create-geofence", "_blank")
                    }
                  />
                </div>
                <AdminPrivilegesResourceTab
                  disabled={!permissions.drivers.write || isViewRule}
                  tabType="geofences"
                  customTitleFirstGroup={t(
                    "admin.notifications.actions.entity.itemsIncluded"
                  )}
                  customTitleSecondGroup={t(
                    "admin.notifications.actions.entity.itemsNotIncluded"
                  )}
                  leftButtonText={t(
                    "admin.groups.editGroups.widgetButtonLabelRemove"
                  )}
                  rightButtonText={t(
                    "admin.groups.editGroups.widgetButtonLabelAdd"
                  )}
                  rightGroupProp={geofencesCannotSee}
                  leftGroupProp={geofencesCanSee}
                  output={(
                    leftResources: ResourceProps[],
                    rightResources: ResourceProps[]
                  ) => {
                    setPrivilegesHasChangedOutSide(true);
                    // Updating setModifiedPrivilege with selected elements
                    const modifiedPrivilegeTemp = {
                      ...modifiedPrivilege,
                      geofence: rightResources.map((resource) => resource.id),
                    } as SelectedEntities;
                    setModifiedPrivilege(modifiedPrivilegeTemp);
                    setModifiedPrivilegeOutside(modifiedPrivilegeTemp);

                    setGeofencesCanSee(rightResources);
                    setGeofencesCanSeeOutside(rightResources);
                    setGeofencesCannotSee(leftResources);
                  }}
                />
              </TabSection>
            </Tabs>
          </div>
        )}
      </>
    );
  };
export default AdminNotificationRuleEntities;
